export const getRootPath = (pathname: string) => {
    return pathname.split("/")[1];
};

export const getUserInitials = (firstName: string, lastName: string) => {
    return `${firstName?.slice(0, 1)}${lastName?.slice(0, 1) || ""}`;
};

export const ObjectId = (m = Math, d = Date, h = 16, s = (s: number) => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

export const generateRandomString = (length: number): string => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};
