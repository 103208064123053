import { createApi } from "@reduxjs/toolkit/query/react";

import {
    ICreateOrganizationBody,
    ICreateOrganizationResponse,
    IDeleteOrganizationBody,
    IDeleteOrganizationResponse,
    IGetOrganizationByIdQuery,
    IGetOrganizationByIdResponse,
    IGetOrganizationsQuery,
    IGetOrganizationsResponse,
    ISetOrganizationManagersBody,
    ISetOrganizationManagersResponse,
    ISetOrganizationProjectsBody,
    ISetOrganizationProjectsResponse,
    // ISetOrganizationManagersBody,
    // ISetOrganizationManagersResponse,
    IUpdateOrganizationBody,
    IUpdateOrganizationResponse,
} from "../../types/organizations";
import { getOrganizationsQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "organizations";

export const organizationsApi = createApi({
    reducerPath: "organizationsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Organizations", "Organization"],
    endpoints: build => ({
        getOrganizations: build.query<IGetOrganizationsResponse, IGetOrganizationsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getOrganizationsQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result?.data.organizations
                    ? [...result.data.organizations.map(({ id }) => ({ type: "Organizations" as const, id })), "Organizations"]
                    : ["Organizations"]),
        }),
        createOrganization: build.mutation<ICreateOrganizationResponse, ICreateOrganizationBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Organizations"],
        }),

        getOrganizationById: build.query<IGetOrganizationByIdResponse, IGetOrganizationByIdQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.organizationId}`,
                method: "GET",
            }),
            providesTags: (result, _error, arg) => [{ type: "Organization", id: arg.organizationId }],
        }),
        updateOrganization: build.mutation<IUpdateOrganizationResponse, IUpdateOrganizationBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.organizationId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Organization", id: arg.organizationId }, "Organizations"],
        }),
        setOrganizationManagers: build.mutation<ISetOrganizationManagersResponse, ISetOrganizationManagersBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-organization-managers/${data.organizationId}`,
                method: "PATCH",
                body: {
                    managerIds: data.managerIds,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Organization", id: arg.organizationId }, "Organizations"],
        }),
        setOrganizationProjects: build.mutation<ISetOrganizationProjectsResponse, ISetOrganizationProjectsBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-organization-projects/${data.organizationId}`,
                method: "PATCH",
                body: {
                    projectIds: data.projectIds,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Organization", id: arg.organizationId }, "Organizations"],
        }),
        deleteOrganization: build.mutation<IDeleteOrganizationResponse, IDeleteOrganizationBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Organization", id: arg.id }, "Organizations"],
        }),
    }),
});

export const {
    useGetOrganizationsQuery,
    useCreateOrganizationMutation,
    useGetOrganizationByIdQuery,
    useUpdateOrganizationMutation,
    useSetOrganizationManagersMutation,
    useSetOrganizationProjectsMutation,
    useDeleteOrganizationMutation,
} = organizationsApi;
