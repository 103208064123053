import { useEffect, useMemo } from "react";

import { useGetUserDataQuery } from "../store/auth/api";
import { setUserData } from "../store/auth/reducer";
import { useAppDispatch, useAppSelector } from "../store/index";
import { IUser } from "../types/users";
import { getUserInitials } from "../utils/strings";

export const useUser = (): {
    user?: IUser
    userInitials?: string
} => {
    const dispatch = useAppDispatch();
    const { data } = useGetUserDataQuery();
    const userData = useAppSelector(state => state.auth.userData);

    const userInitials = useMemo(() => {
        return userData?.user && getUserInitials(userData?.user.info.firstName, userData?.user.info.lastName);
    }, [userData?.user]);

    useEffect(() => {
        if (data) {
            dispatch(setUserData(data));
        }
    }, [data, dispatch]);

    return {
        user: userData?.user,
        userInitials,
    };
};
