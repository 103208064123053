import React, { FC } from "react";

import { Navigate } from "react-router-dom";

import MainWrapper from "./MainWrapper";
import { getAccessToken } from "../utils/auth";

interface PrivateRouteProps {
    component: FC<any>;
    props?: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...props }) => {
    const token = getAccessToken();

    return token ? (
        <MainWrapper>
            <Component {...props} />
        </MainWrapper>
    ) : (
        <Navigate to="/auth" />
    );
};

export default PrivateRoute;
