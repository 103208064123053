export const setAccessToken = (token: string) => {
    try {
        const rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe && JSON.parse(rememberMe)) {
            localStorage.setItem("token", JSON.stringify(token));
        } else {
            sessionStorage.setItem("token", JSON.stringify(token));
        }
    } catch (e) {
        console.error(e);
    }
};

export const getAccessToken = () => {
    try {
        const rememberMe = localStorage.getItem("rememberMe");
        let token;
        if (rememberMe && JSON.parse(rememberMe)) {
            token = localStorage.getItem("token");
        } else {
            token = sessionStorage.getItem("token");
        }

        return `${JSON.parse(token || "")}`;
    } catch (e) {
        return "";
    }
};

export const removeAccessToken = () => {
    try {
        const rememberMe = localStorage.getItem("rememberMe");
        if (rememberMe && JSON.parse(rememberMe)) {
            localStorage.removeItem("token");
        } else {
            sessionStorage.removeItem("token");
        }
    } catch (e) {
        console.error(e);
    }
};

export const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};
