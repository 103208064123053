import "./styles/index.less";

import React, { Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute";
import { ROUTES } from "./pages";

export const App = () => {
    return (
        <Routes>
            {ROUTES.map(route => {
                const RouteComponent = route.isPrivate ? (
                    <PrivateRoute component={route.component} />
                ) : (
                    <route.component />
                );
                return (
                    <Route
                        key={route.id}
                        path={route.path}
                        element={<Suspense fallback={null}>{RouteComponent}</Suspense>}
                    />
                );
            })}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};
