import { createApi } from "@reduxjs/toolkit/query/react";

import {
    IGetClientByIdQuery,
    IGetClientByIdResponse,
    IGetClientsQuery,
    IGetClientsResponse,
    ISetClientPersonnelBody,
    ISetClientPersonnelResponse,
    ISetClientProjectBody,
    ISetClientProjectResponse,
    IUpdateClientByIdBody,
    IUpdateClientByIdResponse,
} from "../../types/clients";
import { getClientsQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "clients";

export const clientsApi = createApi({
    reducerPath: "clientsApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Clients", "Client"],
    endpoints: build => ({
        getClients: build.query<IGetClientsResponse, IGetClientsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getClientsQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.clients.map(({ id }) => ({ type: "Clients" as const, id })), "Clients"]
                    : ["Clients"]),
        }),
        getClientById: build.query<IGetClientByIdResponse, IGetClientByIdQuery>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "GET",
            }),
            providesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }],
        }),
        updateClientById: build.mutation<IUpdateClientByIdResponse, IUpdateClientByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.clientId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients"],
        }),
        setClientPersonnel: build.mutation<ISetClientPersonnelResponse, ISetClientPersonnelBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-client-personnel/${data.clientId}`,
                method: "PATCH",
                body: {
                    personnelIds: data.personnelIds,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients"],
        }),
        setClientProject: build.mutation<ISetClientProjectResponse, ISetClientProjectBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/set-project/${data.clientId}`,
                method: "PATCH",
                body: {
                    project: data.projectId,
                },
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: "Client", id: arg.clientId }, "Clients"],
        }),
    }),
});

export const {
    useGetClientsQuery,
    useGetClientByIdQuery,
    useUpdateClientByIdMutation,
    useSetClientPersonnelMutation,
    useSetClientProjectMutation,
} = clientsApi;
