import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authApi } from "./api";
import { IGetUserDataResponse } from "../../types/auth";

interface AuthState {
    userData: IGetUserDataResponse | null;
}

const initialState: AuthState = {
    userData: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<IGetUserDataResponse>) => {
            state.userData = action.payload;
        },
        clearUserData: state => {
            state.userData = null;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            authApi.endpoints.getUserData.matchFulfilled,
            (state, { payload }) => {
                state.userData = payload;
            }
        );
    },
});

export const { setUserData, clearUserData } = authSlice.actions;

export default authSlice.reducer;
