import { useEffect, useMemo, useState } from "react";

import { Avatar, Col, Divider, Row } from "antd";
import { BsPerson, BsListColumnsReverse, BsListColumns, BsPersonLinesFill } from "react-icons/bs";
import { GrProjects } from "react-icons/gr";
import { IoPieChartSharp } from "react-icons/io5";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";

import NavigationItem from "./components/NavigationItem";
import { NavigationContext } from "./contexts/NavigationContext";
import { useUser } from "../../hooks/useUser";
// import Logo from "../../assets/icons/logo.svg";

const Navigation = ({
    navbarWidth,
    navbarIsCollapsed,
    setNavbarIsCollapsed,
}: {
    navbarWidth: number;
    navbarIsCollapsed: boolean;
    setNavbarIsCollapsed: (_navbarIsCollapsed: boolean) => void;
}) => {
    const location = useLocation();
    const { user, userInitials } = useUser();

    const [selectedPath, setSelectedPath] = useState(location.pathname);

    const isProjectListAllowed = useMemo(() => {
        return !!user?.role.info.id && !["user", "therapist", "assistant"].includes(user?.role.info.id);
    }, [user]);

    useEffect(() => {
        setSelectedPath(location.pathname);
    }, [location.pathname]);

    return (
        <NavigationContext.Provider
            value={{
                navbarIsCollapsed,
                selectedPath,
                onPathSelect: setSelectedPath,
            }}
        >
            <div style={{ width: navbarWidth, height: "100%" }} className="vertical-space-between">
                <div style={{ width: "100%" }}>
                    <div style={{ height: "50px", width: navbarIsCollapsed ? "40px" : "auto", marginLeft: "20px", display: "flex", overflow: "hidden" }} className={"mt20 bold"}>
                        {/* <img alt={"about us"} src={Logo}/> */}
                        Thinkable
                    </div>
                    {user ? <Col>
                        <Row className="center">
                            <Avatar size="default">
                                {userInitials}
                            </Avatar>
                            {!navbarIsCollapsed &&
                                <div className={"ml20"}>
                                    <div className={"mt4 fs12 horizontal-right"}>
                                        {user.role.info.name}
                                    </div>
                                </div>
                            }
                        </Row>
                        {!navbarIsCollapsed &&
                            <Row className={"bold horizontal-center mt20"}>
                                {user.info.firstName + " " + user.info.lastName}
                            </Row>
                        }
                    </Col>
                        : <div style={{ width: "100%", height: "69px" }}></div>
                    }

                    <Divider />

                    <div className={navbarIsCollapsed ? "ph12" : "ph20"}>
                        <div className={"mt10"}>
                            <NavigationItem icon={<IoPieChartSharp />} label={"Dashboard"} path={"/"} />
                        </div>

                        {isProjectListAllowed && <>
                            <div className={"mt10"}>
                                <NavigationItem icon={<BsListColumnsReverse />} label={"Organizations"} path={"/organizations"} />
                            </div>

                            <div className={"mt10"}>
                                <NavigationItem icon={<BsListColumns />} label={"Projects"} path={"/projects"} />
                            </div>

                            <div className={"mt10"}>
                                <NavigationItem icon={<BsPersonLinesFill />} label={"Project Managers"} path={"/managers"} />
                            </div>
                        </>}

                        {!isProjectListAllowed &&
                            <div className={"mt10"}>
                                <NavigationItem icon={<GrProjects />} label={"Project"} path={`/projects/${user?.project?.id}`} />
                            </div>
                        }

                        {isProjectListAllowed &&
                            <div className={"mt10"}>
                                <NavigationItem icon={<BsPersonLinesFill />} label={"Unassigned Clients"} path={"/clients"} />
                            </div>
                        }

                        <div className={"mt10"}>
                            <NavigationItem icon={<BsPerson />} label={"Profile"} path={"/profile"} />
                        </div>
                    </div>
                </div>

                <div style={{ width: "100%" }} className="mb20">
                    <div className={navbarIsCollapsed ? "ph12" : "ph20"}>
                        <div className={navbarIsCollapsed ? "horizontal-center" : "horizontal-right"}>
                            {navbarIsCollapsed ? (
                                <MdOutlineKeyboardDoubleArrowRight
                                    className={"grey-4-color fs20"}
                                    style={{ marginTop: -20, cursor: "pointer" }}
                                    onClick={() => {
                                        setNavbarIsCollapsed(false);
                                        localStorage.setItem("isNavOpen", "false");
                                    }}
                                />
                            ) : (
                                <MdOutlineKeyboardDoubleArrowLeft
                                    className={"grey-4-color fs26"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setNavbarIsCollapsed(true);
                                        localStorage.setItem("isNavOpen", "true");
                                    }}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </NavigationContext.Provider>
    );
};

export default Navigation;
