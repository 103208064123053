import { createApi } from "@reduxjs/toolkit/query/react";

import {
    IGetInviteTokensResponse,
    IGetInviteTokensQuery,
    ICreateInviteTokenResponse,
    ICreateInviteTokenBody,
    IGetModulesFromFreeTextBody,
    IGetModulesFromFreeTextResponse,
    ISetModulesDictionaryResponse,
    ISetModulesDictionaryBody,
    IGetModulesDictionaryQuery,
    IGetModulesDictionaryResponse,
} from "../../types/inviteTokens";
import { getInviteTokensQueryString, getModulesDictionaryQueryString } from "../../utils/queries";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "inviteTokens";

export const inviteTokensApi = createApi({
    reducerPath: "inviteTokensApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["inviteTokens"],
    endpoints: build => ({
        getInviteTokens: build.query<IGetInviteTokensResponse, IGetInviteTokensQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getInviteTokensQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result?.inviteTokens
                    ? [...result.inviteTokens.map(({ id }) => ({ type: "inviteTokens" as const, id })), "inviteTokens"]
                    : ["inviteTokens"]),
        }),
        getModulesDictionary: build.query<IGetModulesDictionaryResponse, IGetModulesDictionaryQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}/modules-dictionary?${getModulesDictionaryQueryString(query)}`,
            }),
            providesTags: [],
        }),
        createInviteToken: build.mutation<ICreateInviteTokenResponse, ICreateInviteTokenBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["inviteTokens"],
        }),
        setModulesDictionary: build.mutation<ISetModulesDictionaryResponse, ISetModulesDictionaryBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/modules-dictionary`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["inviteTokens"],
        }),
        getModulesFromFreeText: build.mutation<IGetModulesFromFreeTextResponse, IGetModulesFromFreeTextBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/get-free-text-modules`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: [],
        }),
    }),
});

export const {
    useGetInviteTokensQuery,
    useGetModulesDictionaryQuery,
    useCreateInviteTokenMutation,
    useSetModulesDictionaryMutation,
    useGetModulesFromFreeTextMutation,
} = inviteTokensApi;
