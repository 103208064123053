import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQueryWithIntercept } from "../connection/api";

const FILES_ENDPOINT_PREFIX = "files";

export const filesApi = createApi({
    reducerPath: "filesApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Files"],
    endpoints: build => ({
        uploadFile: build.mutation({
            query: ({ file, path, type }) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("path", path);
                formData.append("type", type);

                return {
                    url: `${FILES_ENDPOINT_PREFIX}`,
                    method: "POST",
                    body: formData,
                };
            },
        }),
    }),
});

export const fetchFile = async (url: string): Promise<Blob | undefined> => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${FILES_ENDPOINT_PREFIX}?url=${encodeURIComponent(url)}`);
        const fileBlob = await response.blob();
        return fileBlob;
    } catch (err) {
        console.error("Error fetching file:", err);
    }
};

export const {
    useUploadFileMutation,
} = filesApi;
