import { createApi } from "@reduxjs/toolkit/query/react";
import { message } from "antd";

import { ISignUpPayload, ISignUpResponse, ISignInResponse, ISignInPayload, IGetUserDataResponse, IUserRequestBody } from "../../types/auth";
import { setAccessToken } from "../../utils/auth";
import { baseQueryWithIntercept } from "../connection/api";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Auth"],
    endpoints: builder => ({
        adminSignUp: builder.mutation<ISignUpResponse, ISignUpPayload>({
            query: data => ({
                url: "/auth/create-admin",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    setAccessToken(data.token);
                } catch (error: any) {
                    const errorMessage = error?.error?.data?.message;
                    message.error(errorMessage ? `Admin Sign-up failed: ${errorMessage}` : "Admin Sign-up failed");
                }
            },
        }),
        signIn: builder.mutation<ISignInResponse, ISignInPayload>({
            query: data => ({
                url: "/auth/sign-in",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    setAccessToken(data.token);
                } catch (error: any) {
                    const errorMessage = error?.error?.data?.message;
                    message.error(errorMessage ? `Sign-in failed: ${errorMessage}` : "Sign-in failed");
                }
            },
        }),
        getUserData: builder.query<IGetUserDataResponse, void>({
            query: () => ({
                url: "/auth/user-data",
            }),
            providesTags: ["Auth"],
        }),

        updateCurrentUser: builder.mutation({
            query(data: IUserRequestBody) {
                return {
                    url: "auth/",
                    method: "PATCH",
                    body: data.data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
    }),
});

export const {
    useAdminSignUpMutation,
    useSignInMutation,
    useUpdateCurrentUserMutation,
    useGetUserDataQuery,
} = authApi;
